import React, { useState, useEffect } from "react";
import TrackVisibility from "react-on-screen";

import Sectiontitle from "../components/Sectiontitle";
// import Smalltitle from '../components/Smalltitle';
import Progress from "../components/Progress";
import Resume from "../components/Resume";
import SEO from "../components/seo"

const Smalltitle = React.lazy(() =>
  import("../components/Smalltitle")
)

function Resumes(){
  const isSSR = typeof window === "undefined"

  const [skills] = useState([
    {
      title: 'Javascript',
      value: 95,
    },
    {
      title: 'NodeJS',
      value: 95,
    },
    {
      title: 'React',
      value: 90,
    },
    {
      title: 'Infrastructure Management',
      value: 90,
    },
    {
      title: 'Docker',
      value: 85,
    },
    {
      title: 'Application Design',
      value: 80,
    },
    {
      title: 'Ruby (Rails)',
      value: 80,
    },
    {
      title: 'Kubernetes',
      value: 75,
    },
    {
      title: 'PHP',
      value: 75,
    },
  ]);

  const [workingExperience] = useState([
    {
      id: 1,
      year: '2019 - Present',
      position: 'Software Developer',
      company: 'Kambeo',
      details: 'I joined Kambeo to help them further their mission to empower charities and non-profits to raise the funds needed to help people in need.',
      website: 'https://kambeo.io/'
    },
    {
      id: 2,
      year: '2019 - 2021',
      position: 'Intermediate Developer',
      company: 'Link2Feed',
      details: 'I joined Link2Feed to help expand their existing cloud product suite. Here I am working on helping others feed change.',
      website: 'https://www.link2feed.com/'
    },
    {
      id: 3,
      year: '2016 - 2019',
      position: 'Software Developer',
      company: 'VIZIYA Corp.',
      details: 'At VIZIYA I lead development of their IIoT product as well as architected and began developing their industry first cloud platform. Here I got a lot of experience working with larger teams and designing cloud systems.',
      website: 'https://www.viziya.com/'
    },
    {
      id: 4,
      year: '2015 - 2016',
      position: 'Full Stack Developer',
      company: 'industrialwebapps.com, Inc.',
      details: 'At IWA I helped begin moving a large MeteorJS project over to using React as its front end technology. I also worked with creating systems that allowed users to import Microsoft Excel spreadsheets to create forms that could then be cloned, filled out, and submitted for reporting on later.',
      website: 'https://industrialwebapps.com/'
    },
  ]);

  const [educationExperience] = useState([
    {
      id: 1,
      year: '2014 - 2016',
      graduation: 'Internet Programming and Database Management - Post-Grad Diploma',
      university: 'Lambton College',
      details: '',
    },
    {
      id: 2,
      year: '2011 - 2014',
      graduation: 'Game Development',
      university: 'Niagara College of Canada',
      details: '',
    },
    {
      id: 3,
      year: '2009 - 2010',
      graduation: 'High School High Skill Major',
      university: 'St. Christopher Secondary School/Lambton College',
      details: 'Database Modeling and Linux courses provided through the college were used to complete the requirements of the high skills major offered by the St. Clair Catholic District School Board.',
    }
  ]);

  return (
    <>
      <SEO title="Resume" />
      <div className="mi-skills-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="My Skills" />
          <div className="mi-skills">
            <div className="row mt-30-reverse">
              {skills.map(skill => (
                <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                  <Progress title={skill.title} percentage={skill.value} />
                </TrackVisibility>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mi-resume-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Resume" />
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <Smalltitle title="Working Experience" icon="briefcase" />
            </React.Suspense>
          )}
          <div className="mi-resume-wrapper">
            {workingExperience.map(workingExp => (
              <Resume key={workingExp.id} resumeData={workingExp} />
            ))}
          </div>
          <div className="mt-30"></div>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <Smalltitle title="Educational Qualifications" icon="book" />
            </React.Suspense>
          )}
          <div className="mi-resume-wrapper">
            {educationExperience.map(educatonExp => (
              <Resume key={educatonExp.id} resumeData={educatonExp}/>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Resumes;
