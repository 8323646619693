import React from "react";

const CompanyOrUniversityNameWithLink = ({ company = '', university = '', website = '' }) => {
  if(website) {
    return (
      <a href={website} ><h6 className="mi-resume-company">{company || university}</h6></a>
    )
  } else {
    return (
      <h6 className="mi-resume-company">{company || university}</h6>
    )
  }
}

function Resume(props) {
  const {year, position, graduation, university, company, details, website} = props.resumeData;
  return (
    <div className="mi-resume mt-30">
      <div className="mi-resume-summary">
        <h6 className="mi-resume-year">{year}</h6>
      </div>
      <div className="mi-resume-details">
        <h5>{position || graduation}</h5>
        <CompanyOrUniversityNameWithLink university={university} company={company} website={website} />
        <p>{details}</p>
      </div>
    </div>
  );
};

export default Resume;
